<template>
  <!-- prettier-ignore -->
  <index-content>
    <search-condition searchable :loading="searching" @do-search="search">
      <ns-company v-model="condition.companyId" all-option />
      <n-field-enum v-model="condition.type" enum-name="ClientType" label="clientType" all-option />
      <n-field-datetime v-model="condition.from" label="from" :disabled="timeDisabled" />
      <n-field-datetime v-model="condition.to" label="to" :disabled="timeDisabled" />
      <n-field-selection v-model="condition.operatorId" selection-name="admin.operator" label="operator" all-option />
      <n-field-text v-model="condition.sessionId" label="sessionId" />
    </search-condition>
    <n-table
      :total="total"
      :page-no="pageNo"
      :page-size="pageSize"
      :searched="searched"
      :searching="searching"
      :has-result="hasResult"
      @on-page-no="onPageNo"
      @on-page-size="onPageSize"
    >
      <table class="table table-striped table-bordered table-hover text-center n-table">
        <thead>
          <tr>
            <th class="col-weight-14">{{ $t('label.company') }}</th>
            <th class="col-weight-14">{{ $t('label.operator') }}</th>
            <th class="col-weight-14">{{ $t('label.app') }}</th>
            <th class="col-weight-17">{{ $t('label.ip') }}</th>
            <th class="col-weight-14">{{ $t('label.type') }}</th>
            <th class="n-table-timestamp">{{ $t('label.loginTime') }}</th>
            <th class="n-table-timestamp">{{ $t('label.expireTime') }}</th>
            <th v-table-action>{{ $t('label.action') }}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="row in records" :key="row.id">
            <td v-selection:[`company.company`]="row.companyId"></td>
            <td>{{ row.loginId }}</td>
            <td class="n-ellipsis" :title="`${row.appName}:${row.appId}`">{{ `${row.appName}:${row.appId}` }}</td>
            <td class="n-ellipsis" :title="row.clientAddress">{{ row.clientAddress }}</td>
            <td v-enums:ClientType="row.clientType"></td>
            <td>{{ row.loginTime | datetime }}</td>
            <td>{{ row.expireTime | datetime }}</td>
            <td class="n-button-box">
              <nb-custom type="info" text="detail" @on-click="openModal(row)" />
              <nb-confirm custom-icon="fas fa-sign-out-alt" text="logout" :re-search="operatorId !== row.operatorId" @on-ok="onLogout(row)" />
            </td>
          </tr>
        </tbody>
      </table>
    </n-table>
  </index-content>
</template>

<script>
import OperatorView from './view';
import PaginateMixin from '@/mixins/paginate-mixin';
import ModalOperatorSession from './ModalOperatorSession';
import { fetch, logout } from '@/api/operator/operator-operator-session';
import { isEmpty } from '@/helpers/utils';

export default OperatorView.extend({
  name: 'OperatorOperatorSession',
  mixins: [PaginateMixin],
  data() {
    return {
      condition: {
        companyId: 0,
        type: 0,
        from: this.oneWeekAgo,
        to: this.eod,
        operatorId: 0,
        sessionId: null,
      },
    };
  },
  computed: {
    timeDisabled() {
      return !isEmpty(this.condition.sessionId);
    },
  },
  methods: {
    doSearch(params) {
      let p = { ...params, ...this.condition };
      if (this.timeDisabled) {
        p.from = undefined;
        p.to = undefined;
      }
      return fetch(p);
    },
    parse(rows) {
      this.records = rows.records;
    },
    openModal(model = {}) {
      this.createModal(ModalOperatorSession, { model, on: this });
    },
    onLogout(row) {
      return logout(row.id);
    },
  },
});
</script>

<style lang="scss" scoped>
@import '~@/style/table';

.n-table {
  min-width: 900px;

  th.rw-action {
    width: 9.5rem;
  }

  th.ro-action {
    width: $action-detail;
  }
}
</style>

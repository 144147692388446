<template>
  <!-- prettier-ignore -->
  <n-modal v-bind="$props" detail>
    <Form ref="modalForm" :model="model" :label-width="labelWidth" class="n-modal-container" @submit.native.prevent>
      <n-row-col2>
        <ns-company :value="model.companyId" disabled />
        <n-field-text :value="model.loginId" label="operator" disabled />
      </n-row-col2>
      <n-row-col2>
        <n-field-text :value="model.clientAddress" label="clientIp" disabled />
        <n-field-text :value="model.sessionId" label="sessionId" disabled />
      </n-row-col2>
      <n-row-col2>
        <n-field-datetime :value="model.loginTime" label="loginTime" disabled />
        <n-field-datetime :value="model.expireTime" label="expireTime" disabled />
      </n-row-col2>
      <n-row-col2>
        <n-field-text :value="model.serverHost" label="serverHost" disabled />
        <n-field-text :value="model.serverPath" label="serverPath" disabled />
      </n-row-col2>
      <n-row-col2>
        <n-field-enum :value="model.clientType" enum-name="ClientType" label="clientType" disabled />
        <n-field-text :value="model.clientAgent" label="clientAgent" disabled />
      </n-row-col2>
      <n-row-col2>
        <n-field-text :value="model.clientSystem" label="clientSystem" disabled />
        <n-field-text :value="model.clientVersion" label="clientVersion" disabled />
      </n-row-col2>
    </Form>
  </n-modal>
</template>

<script>
import NModal from '@/components/common/NModal';

export default NModal.extend({
  name: 'ModalOperatorSession',
  components: { NModal },
});
</script>
